import * as React from 'react';

import { NotFound } from '../components';
import { LanguageContext } from '../context/LanguageContext';

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <LanguageContext.Provider value={'De'}>
      <NotFound />
    </LanguageContext.Provider>
  );
};

export default NotFoundPage;
